<template>
   <main>
      <!-- breadcrumb area start -->
      <section class="breadcrumb__area pt-30 grey-bg">
         <div class="container">
            <div class="row">
               <div class="col-lg-8 offset-lg-2">
                  <div class="breadcrumb__wrapper">
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0">
                           <li class="breadcrumb-item"><router-link to="/"><i class="far fa-home color-theme me-2"></i>Home</router-link></li>
                           <li class="breadcrumb-item active" aria-current="page">Encontre uma revenda</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>

		<!-- map area start -->
      <section class="map__area pt-10 pb-30 grey-bg">
         <div class="container">
            <highcharts :constructor-type="'mapChart'" :options="chartOptions" class="d-flex justify-content-center" />
         </div>
      </section>

		<!-- select map area start -->
      <section class="select__map__area pt-20 pb-20">
         <div class="container">
            <div class="row m-0 justify-content-center justify-content-md-start">
               <div class="wpx-200 px-2 d-flex d-md-block align-items-center mb-2 mb-lg-0">
                  <label class="weight-500 text-uppercase text-dark font-12 me-3 me-md-0">Estado</label>
                  <v-select title="Desktop" :options="estados" v-model="pesquisa.estado" placeholder="Selecione" @input="getCidades(pesquisa.estado, true)">
                     <template v-slot:no-options>Sem resultados</template>
                  </v-select>
                  <select title="Mobile" class="form-control" v-model="pesquisa.estado" @change="getCidades(pesquisa.estado, true)">
                     <option value="" disabled selected>Selecione</option>
                     <option v-for="(option, index) in estados" :key="index" :value="option">{{ option }}</option>
                  </select>
               </div>
               <div class="wpx-350 px-2 d-flex d-sm-block align-items-center mb-2 mb-lg-0">
                  <label class="weight-500 text-uppercase text-dark font-12 me-3 me-md-0">Cidade</label>
                  <v-select title="Desktop" :options="resultado.cidades" v-model="pesquisa.cidade" placeholder="Selecione" label="nome" @input="getRevendedores(false)">
                     <template v-slot:no-options>Sem resultados</template>
                  </v-select>
                  <select title="Mobile" class="form-control" v-model="pesquisa.cidade" @change="getRevendedores(false)">
                     <option value="" disabled selected>Selecione</option>
                     <option v-for="(option, index) in resultado.cidades" :key="index" :value="option">{{ option.nome }}</option>
                  </select>
               </div>
               <div class="wpx-150 px-2 d-flex d-sm-block align-items-center">
                  <label class="weight-500 text-uppercase text-dark font-12 me-3 me-md-0">Limite</label>
                  <v-select title="Desktop" :options="[10, 20, 30, 40, 50]" v-model="pesquisa.limite" :searchable="false" placeholder="Selecione">
                     <template v-slot:no-options>Sem resultados</template>
                  </v-select>
                  <select title="Mobile" class="form-control" v-model="pesquisa.limite">
                     <option value="" disabled selected>Selecione</option>
                     <option v-for="(option, index) in [10, 20, 30, 40, 50]" :key="index" :value="option">{{ option }}</option>
                  </select>
               </div>
               <div class="w-max-content px-2 align-self-end mx-auto mx-md-0">
                  <button class="btn btn-primary font-13" @click="getCoordenadas" v-if="resultado.permissaoLocal == 'granted' || resultado.permissaoLocal == 'prompt'">
                     <i class="far fa-location"></i><span class="ms-1 d-none d-sm-inline"> Usar minha localização</span><span class="ms-1 d-inline d-sm-none"> Meu local</span>
                  </button>
                  <button class="btn btn-primary font-13" @click="abrirAjuda" v-else>
                     <i class="far fa-location-slash"></i><span class="ms-1 d-none d-sm-inline"> Sem acesso a localização</span><span class="ms-1 d-inline d-sm-none"> Sem acesso</span>
                  </button>
               </div>
            </div>
         </div>
      </section>

		<!-- result map area start -->
      <section class="result__map__area pt-20 pb-200 grey-bg">
         <div class="container">
            <div class="row">
               <div class="col-12 pt-150 text-center" v-if="pesquisa.estado == null && resultado.pesquisa.length == 0">Selecione um estado</div>
               <div class="col-12 pt-150 text-center" v-else-if="pesquisa.cidade == null && resultado.pesquisa.length == 0">Selecione uma cidade</div>
               <div class="col-12 pt-150 text-center" v-else-if="resultado.pesquisa.length == 0">Nenhum resultado encontrado</div>
               <div class="col-12 pb-3" v-else>{{ resultado.pesquisa.length }} resultado(s) encontrado(s)</div>
               <div class="col-12" v-if="resultado.pesquisa.length > 0">
                  <div class="card bg-secondary rounded-0 border-0 mb-0">
                     <div class="card-body py-12 px-2">
                        <div class="row m-0 align-items-center text-dark weight-500 text-uppercase font-12">
                           <div class="wpx-200 px-1 d-none d-xxl-block">Cidade</div>
                           <div class="col px-1 d-none d-md-block">Nome</div>
                           <div class="wpx-250 px-1">Endereço</div>
                           <div class="wpx-150 px-1 d-none d-lg-block">Telefone</div>
                           <div class="wpx-200 px-1 d-none d-xl-block">Email</div>
                        </div>
                     </div>
                  </div>
               </div>

               <revenda v-for="(revenda, index) in resultado.pesquisa" :key="index" :revenda="revenda" :index="index" :especializacoes="false" />
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import Vue from 'vue'
import $ from 'jquery'
import { mapState } from 'vuex'
import revenda from '@/components/revendas/Revenda.vue'
import brasilMap from "@highcharts/map-collection/countries/br/br-all.topo.json";

export default {
   name: 'EncontreRevenda',
   data: function () {
      return {
         estados: ['AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MG', 'MS', 'MT', 'PA', 'PB', 'PE', 'PI', 'PR', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO'],
         pesquisa: {'latitude': null, 'longitude': null, 'estado': null, 'cidade': null, 'limite': 10},
         resultado: {'permissaoLocal': 'denied', 'cidades': [], 'pesquisa': []},
         chartInstance: null,
         chartOptions: {
            title: null,
            chart: {
               map: brasilMap,
               width: $(".map__area .container").width(),
               height: $(".map__area .container").width(),
               backgroundColor: '#f9f9f9',
               animation: false,
               events: {
                  load: (event) => {
                     this.chartInstance = event.target;
                  }
               }
            },
            mapNavigation: {enabled: false},
            legend: {enabled: false},
            credits: {enabled: false},
            plotOptions: {
               series: {
                  point: {
                     events: {
                        select: (event) => {
                           if (event.accumulate) {
                              let selecionados = event.target.series.chart.getSelectedPoints();
                              for (var i = 0; i < selecionados.length; i++) {
                                 selecionados[i].select(false, true);
                              }
                           }

                           this.pesquisa.estado = event.target.properties['postal-code'];
                           this.pesquisa.cidade = null;

                           document.querySelector(".select__map__area").scrollIntoView();
                           this.getCidades(this.pesquisa.estado, false)
                        },
                        mouseOver: (event) => {
                           const chart = event.target.series.chart;

                           if (!chart.lbl) {
                              chart.lbl = chart.renderer.label('').attr({
                                 padding: 10,
                                 r: 10,
                                 x: event.target.plotX,
                                 y: event.target.plotY - 60,
                                 zIndex: 999,
                                 fill: '#5cbd0e'
                              }).css({
                                 color: '#fff',
                                 fontSize: 16,
                                 fontWeight: 600
                              }).add();
                           }

                           chart.lbl.show().attr({
                              text: event.target.properties['postal-code'],
                              x: event.target.plotX,
                              y: event.target.plotY - 60
                           });
                        },
                        mouseOut: (event) => {
                           const chart = event.target.series.chart;

                           if (chart.lbl) {
                              chart.lbl.hide();
                           }
                        }
                     }
                  }
               }
            },
            tooltip: {enabled: false},
            series: [{
               dataLabels: {enabled: false, format: "{point.properties.postal-code}"},
               cursor: 'pointer',
               allowPointSelect: true,
               color: '#14397f',
               states: {
                  hover: {
                     color: '#ec2125',
                     borderColor: '#ebebeb'
                  },
                  select: {
                     color: '#ec2125',
                     borderColor: '#ebebeb'
                  }
               },
               data: [
                  ['br-sp', 0], ['br-ma', 0], ['br-pa', 0], ['br-sc', 0], ['br-ba', 0], ['br-ap', 0], ['br-ms', 0], ['br-mg', 0], ['br-go', 0], ['br-rs', 0], 
                  ['br-to', 0], ['br-pi', 0], ['br-al', 0], ['br-pb', 0], ['br-ce', 0], ['br-se', 0], ['br-rr', 0], ['br-pe', 0], ['br-pr', 0], ['br-es', 0],
                  ['br-rj', 0], ['br-rn', 0], ['br-am', 0], ['br-mt', 0], ['br-df', 0], ['br-ac', 0], ['br-ro', 0]
               ]
            }]
         }
      }
   },
   computed: {
		... mapState({
			urlRest: state => state.urlRest
		})
	},
   components: {
      revenda
   },
   methods: {
      abrirAjuda : function () {
         window.open('https://support.google.com/chrome/answer/142065?hl=pt-br')
      },
      getCoordenadas : function () {
         navigator.geolocation.getCurrentPosition((position) => {
            this.pesquisa.latitude = position.coords.latitude;
            this.pesquisa.longitude = position.coords.longitude;
            this.getRevendedores(true)

         }, () => {
            this.resultado.permissaoLocal = 'denied'
         });
      },
      getCidades : function (estado, resetMap) {
			this.$store.dispatch('alternarTelaCarregamento', true)
         this.resultado.pesquisa = [];
         this.pesquisa.cidade = null;

         if (estado) {
            this.pesquisa.estado = estado;
         }

         if (resetMap && this.chartInstance) {
            let selecionados = this.chartInstance.getSelectedPoints()
            
            for (var i = 0; i < selecionados.length; i++) {
               selecionados[i].select(false, true);
            }

            try {
               this.chartInstance.series[0].data[this.chartInstance.series[0].data.map(uf => uf.properties['postal-code']).indexOf(this.pesquisa.estado)].select()
            } catch {
               console.error('Erro ao selecionar estado')
            }
         }

			this.$axios({
				method: 'get',
				url: this.urlRest +'site/getCidades',
            params: {
               estado: this.pesquisa.estado,
               filtro: 'Revendedores'
            }
			}).then(response => {
            this.resultado.cidades = response.data

			}).catch((error) => {
				if (error.response != undefined) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               });
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
      getRevendedores : function (proximidade) {
			this.$store.dispatch('alternarTelaCarregamento', true)
         this.resultado.pesquisa = []

         if (proximidade) {
            this.pesquisa.estado = null
            this.pesquisa.cidade = null
         } else {
            this.pesquisa.latitude = null
            this.pesquisa.longitude = null
         }

			this.$axios({
				method: 'get',
				url: this.urlRest +'site/getRevendedores',
            params: {
               latitude: this.pesquisa.latitude,
               longitude: this.pesquisa.longitude,
               estado: this.pesquisa.estado,
               ibge: this.pesquisa.cidade != null ? this.pesquisa.cidade.ibge : null,
               limite: this.pesquisa.limite
            }
			}).then(response => {
            this.resultado.pesquisa = response.data
            document.querySelector(".select__map__area").scrollIntoView();

			}).catch((error) => {
				if (error.response != undefined) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               });
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      }
   },
   mounted() {
      let ref = this;
      navigator.permissions.query({ name: 'geolocation' }).then(p => this.resultado.permissaoLocal = p.state) // denied, granted, prompt

      Vue.nextTick(function() {
         ref.chartOptions.chart.width = ($(".map__area .container").width() > 800 ? 800 : $(".map__area .container").width())
         ref.chartOptions.chart.height = ($(".map__area .container").width() > 800 ? 800 : $(".map__area .container").width())

         $(window).on("resize", function() {
            ref.chartOptions.chart.width = ($(".map__area .container").width() > 800 ? 800 : $(".map__area .container").width())
            ref.chartOptions.chart.height = ($(".map__area .container").width() > 800 ? 800 : $(".map__area .container").width())
         });
      }.bind(this));
   }
}

</script>

<style scoped>

button.btn-primary {
   padding: 9px 15px;
}

</style>